import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseCurrency } from 'helpers/currency'
import { TEXT_STYLES } from 'TEXT_STYLES'

const CurrencyCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
`

const PriceRange = styled.div`
  align-items: flex-end;
  margin-top: 4px;
  ${TEXT_STYLES.BodyInactive};
`
const centsToDollars = amount => {
  return amount / 100
}

export const PriceCell = ({
  avg,
  max,
  min,
  currencyCode,
  usingAdminMegaQueries,
  isAdminOrImpersonating
}) => {
  const useV2Queries = !isAdminOrImpersonating || usingAdminMegaQueries
  const formattedAvg = useV2Queries ? avg : centsToDollars(avg)
  const formattedMin = useV2Queries ? min : centsToDollars(min)
  const formattedMax = useV2Queries ? max : centsToDollars(max)

  return (
    <CurrencyCellContainer>
      <div>
        {parseCurrency(formattedAvg, currencyCode, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
      {(max || min) && (
        <PriceRange>
          {parseCurrency(formattedMin, currencyCode, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}{' '}
          -{' '}
          {parseCurrency(formattedMax, currencyCode, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </PriceRange>
      )}
    </CurrencyCellContainer>
  )
}

PriceCell.defaultProps = {
  avg: 0,
  max: 0,
  min: 0,
  currencyCode: '',
  usingAdminMegaQueries: false,
  isAdminOrImpersonating: false
}

PriceCell.propTypes = {
  avg: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  currencyCode: PropTypes.string,
  usingAdminMegaQueries: PropTypes.bool,
  isAdminOrImpersonating: PropTypes.bool
}
