import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledText = styled.span`
  word-break: break-all;
`

export const ExcelExportsTextCell = ({ text }) => {
  const { t } = useTranslation()

  return text ? (
    <StyledText>{text}</StyledText>
  ) : (
    t('market_insights:ShareAndTrends.ExcelExportsTable.notAvailable', 'N/A')
  )
}

ExcelExportsTextCell.defaultProps = {
  text: undefined
}

ExcelExportsTextCell.propTypes = {
  text: PropTypes.string
}
