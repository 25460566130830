import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, Tooltip } from '@junglescout/edna'

const StyledLink = styled.a`
  color: ${props => props.theme.colors.primary};
`

const Text = styled.div`
  color: #898989;
`

const STATUS = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED'
}

export const ExcelExportsDownloadCell = ({ row }) => {
  const { t } = useTranslation()
  const url = row?.original?.url
  const status = row?.original?.status

  if (status === STATUS.PENDING) {
    return (
      <Tooltip
        size="medium"
        side="bottom"
        content={t(
          'market_insights:ShareAndTrends.ExcelExportsTable.tooltip.preparingExport',
          'We’re generating your CSV export. We’ll notify you when it is ready.'
        )}>
        <Text>
          {t(
            'market_insights:ShareAndTrends.ExcelExportsTable.prepare',
            'Preparing export'
          )}
        </Text>
      </Tooltip>
    )
  }

  return status === STATUS.SUCCESS ? (
    <StyledLink href={url} rel="noopener noreferrer" target="_blank">
      <Button btnType={ButtonType.SECONDARY} width="167px">
        {t(
          'market_insights:ShareAndTrends.ExcelExportsTable.download',
          'Download'
        )}
      </Button>
    </StyledLink>
  ) : (
    <Tooltip
      size="medium"
      side="bottom"
      content={t(
        'market_insights:ShareAndTrends.ExcelExportsTable.tooltip.unavailable',
        'We are unable to export this report as this time.'
      )}>
      <Text>
        {t(
          'market_insights:ShareAndTrends.ExcelExportsTable.notAvailable',
          'Unavailable'
        )}
      </Text>
    </Tooltip>
  )
}

ExcelExportsDownloadCell.propTypes = {
  row: PropTypes.shape({ original: PropTypes.shape({ url: PropTypes.string }) })
    .isRequired
}
