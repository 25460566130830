import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { StarRating } from 'ui_elements/StarRating/StarRating'

const RatingCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
`

export const RatingCell = ({ rating }) => {
  return (
    <RatingCellContainer>
      <div>{rating}</div>
      <StarRating rating={rating} size="16" />
    </RatingCellContainer>
  )
}

RatingCell.defaultProps = {
  rating: 0
}

RatingCell.propTypes = {
  rating: PropTypes.number
}
