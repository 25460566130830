import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { weightFormatter } from 'helpers/formatters'

const WeightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0;
`

export const ProductWeightCell = ({ weight, weightUnit }) => {
  return (
    <WeightContainer>{weightFormatter({ weight, weightUnit })}</WeightContainer>
  )
}

ProductWeightCell.propTypes = {
  weight: PropTypes.number.isRequired,
  weightUnit: PropTypes.string.isRequired
}
