import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Icon } from '@junglescout/edna'

const Limit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
const SubFeature = styled(Limit)`
  justify-content: flex-start;
  padding-left: 8px;
`

export const ComparisonFeatures = () => {
  const { t } = useTranslation()

  return (
    <>
      <div>
        {t('upgradeModal.comparison.yourPlan', 'Everything in your plan plus:')}
      </div>
      <div>
        {t('upgradeModal.comparison.rankTracker', 'Tracked Keyword Limit:')}
      </div>
      <div>
        {t('upgradeModal.comparison.productTracker', 'Product Tracker Limit:')}
      </div>
      <div>
        {t(
          'upgradeModal.comparison.productDatabase',
          'Product Database Searches:'
        )}
      </div>
      <div>
        {t('upgradeModal.comparison.keywordScout', 'Keyword Scout Searches:')}
      </div>
      <div>
        {t(
          'upgradeModal.comparison.opportunityFinder',
          'Opportunity Finder Searches:'
        )}
      </div>
      <div>
        {t(
          'upgradeModal.comparison.historicalProductTrackerData',
          'Historical Product Tracker Data:'
        )}
      </div>
      <div>
        {t(
          'upgradeModal.comparison.historicalKeywordData',
          'Historical Keyword Data:'
        )}
      </div>
      <div>
        {t('upgradeModal.comparison.listingBuilder', 'Listing Builder:')}
      </div>
      <div>
        {t('upgradeModal.comparison.aiFeaturesChat', 'AI Assist Chat:')}
      </div>
      <div>{t('upgradeModal.comparison.aiFeatures', 'AI Assist Includes')}</div>
      <SubFeature>
        <Icon name="CHECK" width="14px" height="14px" />
        {t('upgradeModal.comparison.ai.listingBuilder', 'Listing Builder')}
      </SubFeature>
      <SubFeature>
        <Icon name="CHECK" width="14px" height="14px" />
        {t('upgradeModal.comparison.ai.profitAnalysis', 'Profit Analysis')}
      </SubFeature>
      <SubFeature>
        <Icon name="CHECK" width="14px" height="14px" />
        {t('upgradeModal.comparison.ai.reviewAnalysis', 'Review Analysis')}
      </SubFeature>
    </>
  )
}
