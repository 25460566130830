import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseCurrency } from 'helpers/currency'
import { TEXT_STYLES } from 'TEXT_STYLES'

const SellerShareCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
`

const Share = styled.div`
  align-items: flex-end;
  margin-top: 4px;
  ${TEXT_STYLES.BodyInactive};
`

export const SellerShareCell = ({ value, share, currencyCode }) => {
  return (
    <SellerShareCellContainer>
      <div>
        {currencyCode
          ? parseCurrency(value, currencyCode, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
          : Math.round(value).toLocaleString()}
      </div>
      {share && <Share>{`${share.toFixed(1)}%`}</Share>}
    </SellerShareCellContainer>
  )
}

SellerShareCell.defaultProps = {
  value: 0,
  share: 0,
  currencyCode: ''
}

SellerShareCell.propTypes = {
  value: PropTypes.number,
  share: PropTypes.number,
  currencyCode: PropTypes.string
}
