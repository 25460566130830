import React from 'react'
import styled from 'styled-components'
import { ExclamationIcon } from 'icons/ExclamationIcon/ExclamationIcon'
import { Tooltip } from '@junglescout/edna'
import { useTranslation } from 'react-i18next'

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  height: 70px;
  justify-content: flex-end;
  align-items: center;
`
const Dashes = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
  margin-left: 5px;
  margin-top: -2px;
`
const Dash = styled.div`
  height: 2px;
  width: 6px;
  margin-right: 2px;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.grey900};
`
export const EmptyChildCell = cellValueType => {
  const { t } = useTranslation()

  const tooltipContent = t(
    'market_insights:ShareAndTrends.brandTrendsTable.tooltips.childrenWithoutData',
    'This product is part of a variant group that shares a single Best Seller Rank. Due to this, the estimated {{ cellValueType }} for this variant is included in the parent ASIN data above.',
    { cellValueType }
  )

  return (
    <TooltipWrapper>
      <Tooltip side="top" size="medium" content={tooltipContent}>
        <span>
          <ExclamationIcon />
        </span>
      </Tooltip>
      <Dashes>
        <Dash />
        <Dash />
      </Dashes>
    </TooltipWrapper>
  )
}
