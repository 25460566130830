import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import { Button, ButtonType, Separator, Icon } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { PLANS, PLAN_DETAILS } from 'constants/plan_details'
import { sendCtaClickEvent } from 'helpers/segment'

export const highlightColor = '#641AB4'
const highlightBg = '#F7F0FF'

const Wrapper = styled.div`
  width: 188px;
  place-self: flex-end;
  border-radius: 4px;
  border: 1px solid
    ${props => (props.isActive ? highlightColor : props.theme.colors.grey100)};
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
`
const Highlighted = styled.div`
  ${TEXT_STYLES.ButtonWhite}
  font-size: 11px !important;
  color: ${props => props.theme.colors.white};
  background-color: ${highlightColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 26px;
  text-transform: uppercase;
`
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Plan = styled.div`
  ${TEXT_STYLES.BodyBlack}
  font-weight: 500 !important;
  text-transform: uppercase;
`
const Price = styled.div`
  & > .price {
    ${TEXT_STYLES.H2Black}
    font-size: 24px !important;
  }
  & > .price > .interval {
    ${TEXT_STYLES.H2Black}
    font-size: 12px !important;
  }
`
const FullAnnual = styled.div`
  ${TEXT_STYLES.H5Light}
  margin-top: 4px;
`
const Details = styled.div`
  width: 100%;
  div:nth-child(even) {
    background-color: ${props => props.theme.colors.grey025};
  }
`
const Row = styled.div`
  ${TEXT_STYLES.BodyBlack}
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${props => props.isHighlightedFeature && highlightBg};
`

export const PlanDetails = ({ type, frequency }) => {
  const { t } = useTranslation(['generic', 'account'])
  const { colors } = useTheme()

  const plan = PLANS[type]
  const isBrandOwner = type === 'brandOwner'
  const isMonthly = frequency === 'monthly'

  const handleBuyNow = () => {
    const destination = plan.link[frequency]

    sendCtaClickEvent({
      destination,
      text: 'Buy Now',
      location: 'No Plan Modal',
      extraProps: { plan: type, frequency }
    })

    window.open(destination, '_blank')
  }

  return (
    <Wrapper isActive={isBrandOwner}>
      {isBrandOwner && (
        <Highlighted>
          {t('generic:mostPopular', 'Competitive Intelligence')}
        </Highlighted>
      )}

      <Body>
        <PriceContainer>
          <Plan>{plan.name}</Plan>
          <Price>
            <div className="price">
              {plan.price[frequency]}
              <span className="interval">{isMonthly ? '/mo' : '/mo*'}</span>
            </div>
          </Price>
        </PriceContainer>

        <Separator margin="12px 0 0 0" />

        {!isMonthly && (
          <FullAnnual>
            *
            {t('account:noPlanModal.billedAt', 'Billed at {{value}}/yr', {
              value: plan.price.full
            })}
          </FullAnnual>
        )}

        <Button
          btnType={isBrandOwner ? ButtonType.PRIMARY : ButtonType.SECONDARY}
          margin="12px 0"
          onClick={handleBuyNow}>
          {t('generic:buyNow', 'Buy Now')}
        </Button>

        <Details>
          {Object.keys(PLAN_DETAILS).map((feature, index) => {
            const limit = PLAN_DETAILS[feature][type]
            const isHighlightedFeature =
              feature.match(/Competitive Intelligence:/) && limit === 'access'

            if (limit === 'access') {
              return (
                <Row key={index} isHighlightedFeature={isHighlightedFeature}>
                  <Icon
                    name="CHECK_CIRCLE"
                    color={
                      isHighlightedFeature
                        ? colors.graphPurple
                        : colors.green500
                    }
                  />
                </Row>
              )
            }
            return <Row key={index}>{limit}</Row>
          })}
        </Details>
      </Body>
    </Wrapper>
  )
}

PlanDetails.propTypes = {
  type: PropTypes.oneOf([
    'basic',
    'suite',
    'pro',
    'starter',
    'growthAccelerator',
    'brandOwner'
  ]).isRequired,
  frequency: PropTypes.oneOf(['monthly', 'annually']).isRequired
}
