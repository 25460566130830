import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { ToggleCell } from 'ui_elements/TableV2/TableComponents/SharedCells/ToggleCell'
import { TitleCell } from 'ui_elements/TableV2/TableComponents/SharedCells/TitleCell'
import { AmazonLink } from 'ui_elements/AmazonLink/AmazonLink'
import { MARKETPLACES } from 'constants/market_insights/share_and_trends'
import { BRANDS_TABLE_TOOLTIPS } from 'constants/market_insights/brands_table_tooltips'
import { Tooltip } from '@junglescout/edna'
import { QuestionIcon } from 'icons/QuestionIcon/QuestionIcon'

const BrandCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AsinText = styled.div`
  ${TEXT_STYLES.BodyInactive}
  margin-top: 8px;
  width: fit-content;
  padding-right: 8px;
`

const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledAmazonLink = styled(props => <AmazonLink {...props} />)`
  margin: 0 0 0 15px;
`

const NullBrandTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 8px;
  align-items: flex-start;
  justify-content: flex-end;
`

const nameMatchesNullbrand = name => {
  return name === 'Nullbrand'
}

export const SubRowCellTitleCell = ({ row, onAsinClick, is3PSeller }) => {
  const { t } = useTranslation()

  switch (row.depth) {
    case 1: {
      const {
        asin,
        isSharedBsr,
        country,
        sharedBsrVariantChildCount,
        brand,
        isParent
      } = row?.original
      let asinText

      const parentText = t(
        'market_insights:BrandsTrendsTable.parentAsinText',
        '(Parent)'
      )

      if (is3PSeller) {
        asinText = `| ${brand} `

        if (isParent) {
          asinText += parentText
        }
      } else if (isSharedBsr) {
        asinText = parentText
      }

      const shouldExpand =
        (isSharedBsr && !is3PSeller) || (is3PSeller && isParent) || false

      return (
        <ToggleCell row={row} canExpand={shouldExpand}>
          <TitleCell
            product={row?.original}
            isParentAsin={isSharedBsr}
            variantProductTotal={Number(sharedBsrVariantChildCount)}
            asinText={asinText}
            asinButtonDataId="data-id"
            linkAsin={!shouldExpand}
            onAsinClick={() => onAsinClick(country, asin)}
            asinTooltipText={
              <div>
                <Trans i18nKey="market_insights:BrandsTrendsTable.asinTooltip">
                  View in ASIN Explorer
                </Trans>
              </div>
            }
            isZeroNoDataTitle
          />
        </ToggleCell>
      )
    }
    case 2: {
      const { asin, country } = row?.original
      return (
        <ToggleCell row={row} canExpand={false}>
          <TitleCell
            product={row?.original}
            asinButtonDataId="data-id"
            onAsinClick={() => onAsinClick(country, asin)}
            linkAsin
            asinTooltipText={
              <div>
                <Trans i18nKey="market_insights:BrandsTrendsTable.asinTooltip">
                  View in ASIN Explorer
                </Trans>
              </div>
            }
          />
        </ToggleCell>
      )
    }
    default: {
      const { name, totalProducts, id, country, totalAsin } = row?.original
      const isNullBrand = nameMatchesNullbrand(name)
      const brandDisplayName = isNullBrand
        ? t(
            'market_insights:BrandsTrendsTable.nullBrandName',
            'Unknown Product Brands'
          )
        : name
      const marketplace = MARKETPLACES[`${country}`]
      const totalNumber = totalProducts || totalAsin

      const normalizedName = brandDisplayName.toLowerCase()
      const isUnknownSeller =
        is3PSeller &&
        (normalizedName === 'unknown seller' ||
          normalizedName === 'unknown 3p seller')
      const isNullSeller = is3PSeller && normalizedName === 'nullseller'
      let tooltipText = null
      if (isUnknownSeller) {
        tooltipText = t(
          'market_insights:ShareAndTrends.3pSeller.dashboard.common.unknownSellersTooltip',
          'Unknown Seller with 3P sales'
        )
      } else if (isNullSeller) {
        tooltipText = t(
          'market_insights:ShareAndTrends.3pSeller.dashboard.common.nullSellersTooltip',
          'Seller name is not available'
        )
      }
      const url =
        !!marketplace && !!id
          ? `https://${marketplace.domainName}/sp?marketplaceID=${marketplace.id}&seller=${id}`
          : undefined

      const showAmazonLink = is3PSeller && url && !isUnknownSeller

      return (
        <ToggleCell row={row} canExpand={row.depth === 0}>
          <CellWrapper>
            <BrandCellContainer>
              {isUnknownSeller || isNullSeller ? (
                <Tooltip size="medium" side="top" content={tooltipText}>
                  <div>{brandDisplayName}</div>
                </Tooltip>
              ) : (
                <div>{brandDisplayName}</div>
              )}
              <Tooltip
                size="large"
                side="right"
                content={BRANDS_TABLE_TOOLTIPS.asinsCount}>
                <AsinText>
                  <Trans i18nKey="market_insights:BrandsTrendsTable.asinCountText">
                    {{ totalNumber }} ASINs
                  </Trans>
                </AsinText>
              </Tooltip>
            </BrandCellContainer>
            {isNullBrand && (
              <NullBrandTooltipWrapper>
                <Tooltip
                  size="large"
                  side="bottom"
                  align="start"
                  content={
                    <Trans i18nKey="market_insights:BrandsTrendsTable.tooltips.nullBrandTooltip">
                      Jungle Scout is unable to display a brand for this listing
                      at this time. Please check back later.
                    </Trans>
                  }>
                  <NullBrandTooltipWrapper>
                    <QuestionIcon />
                  </NullBrandTooltipWrapper>
                </Tooltip>
              </NullBrandTooltipWrapper>
            )}
            {showAmazonLink && (
              <StyledAmazonLink url={url} onClick={e => e.stopPropagation()} />
            )}
          </CellWrapper>
        </ToggleCell>
      )
    }
  }
}

SubRowCellTitleCell.defaultProps = {
  is3PSeller: false
}

SubRowCellTitleCell.propTypes = {
  row: PropTypes.shape({
    depth: PropTypes.number
  }).isRequired,
  onAsinClick: PropTypes.func.isRequired,
  is3PSeller: PropTypes.bool
}
