import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { parseCurrency } from 'helpers/currency'
import { TrendChangeText } from 'ui_elements/TrendChangeText/TrendChangeText'
import { Tooltip } from '@junglescout/edna'
import { QuestionIcon } from 'icons/QuestionIcon/QuestionIcon'
import { useTranslation } from 'react-i18next'
import { TEXT_STYLES } from 'constants/text_styles'

const CurrencyCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
`

const ValueWrapper = styled.div`
  margin-bottom: 8px;
`

const NoDataRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const NoDataLabel = styled.div`
  ${TEXT_STYLES.NoDataLabel}
`

const NoDataTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  margin-left: 2px;
  align-items: flex-start;
  justify-content: flex-end;
`

export const ProductTrendCell = ({
  value,
  trend,
  currencyCode,
  isAsin,
  decimalPoints,
  hasTrendData
}) => {
  const { t } = useTranslation('market_insights')

  const tooltipText = isAsin
    ? t(
        'market_insights:BrandsTrendsTable.tooltips.noDataAsinTooltip',
        'Amazon has removed Best Seller Rank data for this ASIN at some point during the selected range, or the category is unsupported during this time frame.'
      )
    : t(
        'market_insights:BrandsTrendsTable.tooltips.noDataBrandTooltip',
        'Amazon has removed Best Seller Rank data for some of the ASINs associated with this brand, or the categories are unsupported during this time frame.'
      )
  const displayValue = () => {
    if (currencyCode) {
      return parseCurrency(value, currencyCode, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    }
    if (decimalPoints !== undefined) {
      return parseFloat(value).toLocaleString('en-US', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints
      })
    }
    return Number(value).toLocaleString()
  }

  return (
    <CurrencyCellContainer>
      {value === null ? (
        <NoDataRowContainer>
          <NoDataLabel>No Data</NoDataLabel>
          <NoDataTooltipWrapper>
            <Tooltip
              size="large"
              side="bottom"
              align="end"
              content={tooltipText}>
              <NoDataTooltipWrapper>
                <QuestionIcon />
              </NoDataTooltipWrapper>
            </Tooltip>
          </NoDataTooltipWrapper>
        </NoDataRowContainer>
      ) : (
        <ValueWrapper>{displayValue()}</ValueWrapper>
      )}{' '}
      {hasTrendData ? (
        <TrendChangeText
          value={trend}
          size={12}
          alignLeft
          zeroVsNoDataShareAndTrends
        />
      ) : null}
    </CurrencyCellContainer>
  )
}

ProductTrendCell.defaultProps = {
  currencyCode: undefined,
  decimalPoints: undefined,
  isAsin: false,
  hasTrendData: true
}

ProductTrendCell.propTypes = {
  value: PropTypes.number.isRequired,
  trend: PropTypes.number.isRequired,
  currencyCode: PropTypes.string,
  hasTrendData: PropTypes.bool,
  decimalPoints: PropTypes.number,
  isAsin: PropTypes.bool
}
