import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
`

export const NumberCell = ({ value }) => {
  return (
    <CellContainer>
      {parseFloat(value).toLocaleString({
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}
    </CellContainer>
  )
}

NumberCell.defaultProps = {
  value: 0
}

NumberCell.propTypes = {
  value: PropTypes.number
}
