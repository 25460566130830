import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@junglescout/edna'
import { COLORS } from 'COLORS'

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 75px;
`

const Category = styled.div`
  align-items: flex-start;
  margin-bottom: 3px;
  font-size: 13px;
`

const ViewMore = styled.div`
  align-items: flex-start;
  color: ${COLORS.blue500};
`

export const CategoryCell = ({ categories }) => {
  const { t } = useTranslation('market_insights')
  const catLength = categories.length

  return (
    <CellContainer>
      {categories.slice(0, 1).map(cat => (
        <Category>{cat}</Category>
      ))}
      {catLength > 2 ? (
        <Tooltip
          side="bottom"
          size="medium"
          content={categories.slice(1).map(cat => (
            <Category padding>{cat}</Category>
          ))}>
          <ViewMore>
            {t(
              'market_insights:ShareAndTrends.brandTrendsTable.tooltips.viewMoreCategory',
              ' View {{ length }} More',
              {
                length: catLength - 1
              }
            )}
          </ViewMore>
        </Tooltip>
      ) : (
        <Category>{categories[1]}</Category>
      )}
    </CellContainer>
  )
}

CategoryCell.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired
}
