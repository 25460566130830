import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Icon, Tooltip } from '@junglescout/edna'

import { useUpgradeFeatureLimitInfo } from 'hooks/upgrade_limit_modal'

const Limit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
const IconWrapper = styled.div`
  display: flex;
`

export const NewPlanFeatures = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  const { upgradePlanDetails } = useUpgradeFeatureLimitInfo()

  const renderTooltip = isAdvanced => {
    const tooltipText = isAdvanced
      ? t(
          'upgradeModal.aiAssist.advancedTooltip',
          "Includes answers specific to your business data and Jungle Scout's data, including financial figures, advertising data, sales estimates."
        )
      : t(
          'upgradeModal.aiAssist.limitedTooltip',
          'Includes answers tailored to Jungle Scout knowledge base to easily find answers specific to using Jungle Scouts tools and selling on Amazon.'
        )

    return (
      <Tooltip content={tooltipText} size="large">
        <IconWrapper>
          <Icon
            width="16px"
            height="16px"
            name="TOOLTIP_QUESTION"
            color={colors.grey500}
          />
        </IconWrapper>
      </Tooltip>
    )
  }

  return (
    <>
      <Limit>{upgradePlanDetails.name}</Limit>
      <Limit>{upgradePlanDetails.rankTracker}</Limit>
      <Limit>{upgradePlanDetails.productTrackerLimit}</Limit>
      <Limit>{upgradePlanDetails.productDatabaseSearches}</Limit>
      <Limit>{upgradePlanDetails.keywordScoutSearches}</Limit>
      <Limit>{upgradePlanDetails.opportunityFinderSearches}</Limit>
      <Limit>{upgradePlanDetails.historicalProductTrackerData}</Limit>
      <Limit>{upgradePlanDetails.historicalKeywordScoutData}</Limit>
      <Limit>{upgradePlanDetails.listingBuilder}</Limit>
      <Limit>
        {upgradePlanDetails.aiAssistChat}
        {renderTooltip(upgradePlanDetails.aiAssistChat === 'Advanced')}
      </Limit>
      <Limit>{upgradePlanDetails.aiAssist}</Limit>
    </>
  )
}
