import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Separator, ToggleGroup } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { JSPRO_BUCKET } from 'constants/s3'
import { NO_PLAN_MODAL } from 'constants/account'

import { getPricingPageUrl } from 'helpers/account'
import { stripURLParams } from 'helpers/url'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'

import { Modal } from 'ui_elements/Modal/Modal'

import { PlanDetails } from './PlanDetails'
import { PlanFeatures } from './PlanFeatures'

const StyledModal = styled(Modal)`
  width: 880px;
  .modal-backdrop {
    z-index: 90;
  }
  .modal-container {
    left: calc(50% + ${props => (props.isSidebarOpen ? '125px' : '26px')});
    .modal-content {
      padding: 20px 24px 24px 24px;
    }
    .modal-header {
      display: none;
    }
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
`
const Title = styled.div`
  ${TEXT_STYLES.H2Black}
  font-size: 24px !important;
  margin: 20px 0 8px 0;
`
const SubTitle = styled.div`
  ${TEXT_STYLES.H4Greg}
`
const StyledToggleGroup = styled(ToggleGroup)`
  align-self: flex-end;
`
const PlansContainer = styled.div`
  display: flex;
  gap: 12px;
  margin: 12px 0 20px 0;
`
const LearnMore = styled.div`
  ${TEXT_STYLES.BodyBlack}
  text-align: center;
  color: ${props => props.theme.colors.grey600};
  a {
    color: ${props => props.theme.colors.orange500};
    text-decoration: underline;
  }
`

const LOGO_URL = `${JSPRO_BUCKET}/images/js-icon-small.svg`

export const NoPlanModal = () => {
  const { t } = useTranslation('account')
  const { modal } = useSelector(state => state.globalData)

  const [frequency, setFrequency] = useState('monthly')

  const {
    membershipInfo: { group },
    user: {
      currency,
      checkout_locale: checkoutLocale,
      selected_language: selectedLanguage
    }
  } = useSelector(state => state.globalData)

  const { locked: isSidebarOpen } = useSelector(state => state.sidebar)

  useEffect(() => {
    safeSegmentCall(() => {
      sendSegmentTrackEvent('No Plan Modal Displayed', {
        pageUrl: stripURLParams(window.location?.href)
      })
    })
  }, [])

  const pricingUrl = getPricingPageUrl(
    checkoutLocale,
    currency,
    selectedLanguage,
    group
  )

  return (
    <StyledModal
      isSidebarOpen={isSidebarOpen}
      isVisible={modal === NO_PLAN_MODAL}>
      <ContentContainer>
        <HeaderContainer>
          <img src={LOGO_URL} alt="Jungle Scout logo" />
          <Title>
            {t('account:noPlanModal.title', 'Welcome to Jungle Scout')}
          </Title>
          <SubTitle>
            {t(
              'account:noPlanModal.subtitle',
              'The only platform you need to build and grow a successful business on Amazon. We have three plans for you to choose from.'
            )}
          </SubTitle>
        </HeaderContainer>

        <Separator margin="20px 0" />

        <StyledToggleGroup
          items={[
            {
              content: 'Monthly',
              value: 'monthly'
            },
            {
              content: 'Annual',
              value: 'annually'
            }
          ]}
          value={frequency}
          handleClick={value => setFrequency(value)}
          width="294px"
        />

        <PlansContainer>
          <PlanFeatures />
          <PlanDetails type="starter" frequency={frequency} />
          <PlanDetails type="growthAccelerator" frequency={frequency} />
          <PlanDetails type="brandOwner" frequency={frequency} />
        </PlansContainer>

        <LearnMore>
          <Trans i18nKey="account:noPlanModal.learnMore">
            Learn more about all our plans by viewing our{' '}
            <a href={pricingUrl} target="_blank" rel="noopener noreferrer">
              Pricing Page
            </a>
          </Trans>
        </LearnMore>
      </ContentContainer>
    </StyledModal>
  )
}
