import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.alignment === 'left' ? 'flex-start' : 'flex-end'};
  width: 100%;
`

export const StringCell = ({ value, alignment }) => {
  return <CellContainer alignment={alignment}>{value}</CellContainer>
}

StringCell.defaultProps = {
  value: '',
  alignment: 'right'
}

StringCell.propTypes = {
  value: PropTypes.string,
  alignment: PropTypes.string
}
